import classNames from "classnames";
import { motion } from "framer-motion";

const cardVariants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.75,
    },
  },
  initial: {
    opacity: 0,
    y: 100,
  },
};

export const ExperienceCard = ({ className = "", title, company, content }) => {
  return (
    <motion.div
      variants={cardVariants}
      initial="initial"
      whileInView="visible"
      viewport={{ once: true }}
      className="relative"
    >
      <div className="w-full h-full absolute bottom-frosted-card z-2 rounded-lg top-[10px] shadow" />
      <div
        className={classNames(
          className,
          "flex bg-white p-8 rounded-lg flex-col md:flex-row frosted-card z-1 relative"
        )}
      >
        <div className="w-full md:w-1/4 mb-4 md:mb-0">
          <p className="text-2xl">{title}</p>
          <p className="text-xl pt-4">{company}</p>
        </div>
        <div className="w-full md:w-3/4">
          {content.map((contentString, index) => {
            return (
              <p
                key={`content-string-${index}`}
                className="text-lg pb-2 md:pb-4"
              >
                &#8226; {contentString}
              </p>
            );
          })}
        </div>
      </div>
    </motion.div>
  );
};
