import { motion } from "framer-motion";

export const ProjectCard = ({ name, role, description, image, link }) => {
  const cardVariants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.75,
      },
    },
    initial: {
      opacity: 0,
      y: 100,
    },
  };

  return (
    <motion.div
      variants={cardVariants}
      initial="initial"
      whileInView="visible"
      viewport={{ once: true }}
      className="relative"
    >
      <div className="w-full h-full absolute bottom-frosted-card z-2 rounded-lg top-[10px] shadow" />
      <div className="bg-white rounded-lg p-8 mb-8 h-full frosted-card">
        <div>
          <p className="text-2xl mb-2">{name}</p>
          <p className="text-xl mb-2">{role}</p>
          <p className="text-xl mb-4">{description}</p>
        </div>
        <div>
          <a href={`${link}`} target="_blank">
            <img className="cursor-pointer" src={image} />
          </a>
        </div>
      </div>
    </motion.div>
  );
};
