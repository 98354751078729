import { motion } from "framer-motion";

export const Contact = () => {
  const cardVariants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.75,
      },
    },
    initial: {
      opacity: 0,
      y: 100,
    },
  };

  return (
    <div className="w-full pb-8">
      <div className="w-full container mx-auto px-4 md:px-8 py-8">
        <p className="text-4xl py-8 text-white drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
          Contact and Information
        </p>
        <motion.div
          variants={cardVariants}
          initial="initial"
          whileInView="visible"
          viewport={{ once: true }}
          className="relative"
        >
          <div className="w-full h-full absolute bottom-frosted-card z-2 rounded-lg top-[10px] shadow" />
          <div className="bg-white rounded-lg p-8 frosted-card">
            <p className="text-lg md:text-2xl">
              Email: alexewilliams90@gmail.com
            </p>
            <p className="text-2xl">Mobile: 512-913-4762</p>
            <p className="text-2xl">
              <a
                className="text-blue-600"
                href="https://www.linkedin.com/in/alex-e-williams/"
                target="_blank"
              >
                Linked In
              </a>
            </p>
            <p className="text-2xl">
              <a
                className="text-blue-600"
                href="https://github.com/Zizzs"
                target="_blank"
              >
                Github
              </a>
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};
