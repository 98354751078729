import { ExperienceCard } from "../../components/experience-card";

const experienceData = [
  {
    title: "Frontend Engineer",
    company: "Assemble Inc",
    content: [
      "Contributed to the construction and management of ecommerce web applications alongside a proficient team of experienced developers and engineers.",
      "Played a significant role in the creation of an extensive component library utilized by renowned brands like Apple, Amazon, and other industry leaders.",
      "Took an active role in the initiation and ongoing maintenance of the company's customer-oriented website.",
    ],
  },
  {
    title: "Frontend Developer",
    company: "Idlescape",
    content: [
      "Participated in the planning, construction, and deployment of substantial frontend and backend updates.",
      "Assisted in the oversight and performance monitoring of the operational live service application.",
      "Engaged in productive collaboration with numerous volunteer developers worldwide, operating within a remote environment.",
    ],
  },
  {
    title: "Cloud Engineer",
    company: "Trek10",
    content: [
      "Engaged in the maintenance and development of tailored React applications for government personnel.",
      "Collaborated with a sizeable team of engineers to construct specialized AWS backends, facilitating the extensive frontend framework needs.",
      "Utilized AWS services adeptly in the creation of serverless cloud infrastructure.",
    ],
  },
];

export const Experience = () => {
  return (
    <div className="w-full pb-8">
      <div className="container mx-auto px-4 md:px-8 md:py-8">
        <p className="text-4xl py-8 text-white drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
          Recent Experience
        </p>
        <div className="flex flex-col gap-8">
          {experienceData.map((data, index) => {
            return (
              <ExperienceCard
                key={`experience-card-${index}`}
                className="w-full"
                {...data}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
