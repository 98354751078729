import { ProjectCard } from "../../components/project-card";
import assembleImage from "../../images/assemble_website.png";
import curologyImage from "../../images/curology_website.png";

const projects = [
  {
    name: "Assemble Inc Website",
    role: "Frontend Developer",
    description:
      "With a small team, built the Assemble Inc website from the ground up using React, Typescript, Next.js, Netlify, and Contentstack.",
    image: assembleImage,
    link: "//assembleinc.com",
  },
  {
    name: "Curology Ecommerce Storefront",
    role: "Frontend Developer",
    description:
      "Worked with the Curology team and a small group of developers to create a new ecommerce storefront to sell beauty products.",
    image: curologyImage,
    link: "//shop.curology.com",
  },
];

export const Projects = () => {
  return (
    <div className="w-full pb-16">
      <div className="w-full container mx-auto px-4 md:px-8 pt-8">
        <p className="text-4xl py-8 text-white drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
          Projects
        </p>
        <div className="flex flex-col md:flex-row mx-auto gap-8">
          {projects.map((project, index) => {
            return <ProjectCard key={`project-card-${index}`} {...project} />;
          })}
        </div>
      </div>
    </div>
  );
};
