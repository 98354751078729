import { Contact } from "./sections/contact";
import { Experience } from "./sections/experience";
import { Hero } from "./sections/hero";
import { Projects } from "./sections/projects";
import { Skills } from "./sections/skills";

import backgroundImage from "./images/background_image.png";

function App() {
  return (
    <div className="relative w-full h-full">
      <img className="z-3 h-full w-full fixed" src={backgroundImage} />
      <div className="z-2 fixed blur-image" />
      <div className="z-1 relative">
        <Hero />
        <Experience />
        <Skills />
        <Projects />
        <Contact />
      </div>
    </div>
  );
}

export default App;
