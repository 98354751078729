import classNames from "classnames";
import { motion } from "framer-motion";

const cardVariants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.75,
    },
  },
  initial: {
    opacity: 0,
    y: 100,
  },
};

export const SkillsPill = ({ skill, color, text }) => {
  return (
    <motion.div
      variants={cardVariants}
      initial="initial"
      whileInView="visible"
      viewport={{ once: true }}
      className={classNames(
        color,
        text,
        "rounded-lg p-2 w-[10rem] text-center text-[20px] shadow"
      )}
    >
      {skill}
    </motion.div>
  );
};
