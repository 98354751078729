import { useTypingText } from "../../hooks/useTypingText";
import { motion } from "framer-motion";

export const Hero = () => {
  const { word } = useTypingText(
    [
      "Experienced",
      "Innovative",
      "Creative",
      "Adaptable",
      "Resourceful",
      "Experienced",
      "Proficient",
      "Diligent",
      "Meticulous",
    ],
    130,
    20
  );

  const titleVariants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
      },
    },
    initial: {
      opacity: 0,
      y: 100,
    },
  };

  const isVariants = {
    visible: {
      opacity: 1,
      y: 50,
      transition: {
        duration: 1,
        delay: 0.3,
      },
    },
    initial: {
      opacity: 0,
      y: 150,
    },
  };

  const wordVariants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
        delay: 0.7,
      },
    },
    initial: {
      opacity: 0,
      x: -100,
    },
  };

  return (
    <div className="w-full mt-8 h-[100vh]">
      <div className="container mx-auto px-4 md:px-8 h-full justify-center items-left gap-8 flex flex-col">
        <div className="flex flex-col lg:flex-row flex-wrap">
          <motion.p
            variants={titleVariants}
            initial="initial"
            animate="visible"
            className="text-[60px] md:text-[80px] lg:text-[180px] underline"
          >
            alex williams
          </motion.p>
          <motion.p
            variants={isVariants}
            initial="initial"
            animate="visible"
            className="text-[30px] md:text-[40px] lg:text-[100px] mt-[-40px] xl:mt-0 ml-0 xl:ml-10"
          >
            is...
          </motion.p>
        </div>

        <motion.p
          variants={wordVariants}
          initial="initial"
          animate="visible"
          className="text-xl md:text-xl lg:text-3xl pt-8 text-white"
        >
          {word}
        </motion.p>
      </div>
    </div>
  );
};
