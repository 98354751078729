import { SkillsPill } from "../../components/skills-pill";

const skills = [
  {
    skill: "Typescript",
    color: "bg-fuchsia-400",
    text: "text-white",
  },
  {
    skill: "Javascript",
    color: "bg-fuchsia-400",
    text: "text-white",
  },
  {
    skill: "C#",
    color: "bg-fuchsia-400",
    text: "text-white",
  },
  {
    skill: "C++",
    color: "bg-fuchsia-400",
    text: "text-white",
  },
  {
    skill: "Python",
    color: "bg-fuchsia-400",
    text: "text-white",
  },
  {
    skill: "React.js",
    color: "bg-blue-400",
    text: "text-white",
  },
  {
    skill: "Vue.js",
    color: "bg-blue-400",
    text: "text-white",
  },
  {
    skill: "Angular",
    color: "bg-blue-400",
    text: "text-white",
  },
  {
    skill: "Node.js",
    color: "bg-blue-400",
    text: "text-white",
  },
  {
    skill: "Express",
    color: "bg-blue-400",
    text: "text-white",
  },
  {
    skill: "Next.js",
    color: "bg-blue-400",
    text: "text-white",
  },
  {
    skill: "Tailwind",
    color: "bg-blue-400",
    text: "text-white",
  },
  {
    skill: "SCSS",
    color: "bg-blue-400",
    text: "text-white",
  },
  {
    skill: "SASS",
    color: "bg-blue-400",
    text: "text-white",
  },
  {
    skill: "Material UI",
    color: "bg-blue-400",
    text: "text-white",
  },
  {
    skill: "Builder.io",
    color: "bg-purple-400",
    text: "text-white",
  },
  {
    skill: "Contentstack",
    color: "bg-purple-400",
    text: "text-white",
  },
  {
    skill: "Prismic",
    color: "bg-purple-400",
    text: "text-white",
  },
  {
    skill: "BigCommerce",
    color: "bg-purple-400",
    text: "text-white",
  },
  {
    skill: "DynamoDB",
    color: "bg-rose-400",
    text: "text-white",
  },
  {
    skill: "MySQL",
    color: "bg-rose-400",
    text: "text-white",
  },
  {
    skill: "PostgreSQL",
    color: "bg-rose-400",
    text: "text-white",
  },
  {
    skill: "MongoDB",
    color: "bg-rose-400",
    text: "text-white",
  },
  {
    skill: "Lambda",
    color: "bg-green-400",
    text: "text-white",
  },
  {
    skill: "API Gateway",
    color: "bg-green-400",
    text: "text-white",
  },
  {
    skill: "Eventbridge",
    color: "bg-green-400",
    text: "text-white",
  },
  {
    skill: "Cognito",
    color: "bg-green-400",
    text: "text-white",
  },
  {
    skill: "Netlify",
    color: "bg-green-400",
    text: "text-white",
  },
];

export const Skills = () => {
  return (
    <div className="w-full pb-8">
      <div className="container mx-auto px-4 md:px-8 md:py-8">
        <p className="text-4xl py-8 text-white drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
          Technical Skills
        </p>
        <div className="relative">
          <div className="w-full h-full absolute bottom-frosted-card z-2 rounded-lg top-[10px] shadow" />
          <div className="flex flex-row flex-wrap gap-4 justify-center md:justify-between bg-white p-8 rounded-lg frosted-card">
            {skills.map((skill, index) => {
              return <SkillsPill key={`skills-pill-${index}`} {...skill} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
